<template>



  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>

              <b-col
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="退货通知单编号:"
                    label-for="contact"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      v-model="condition.salesreturnboundNo"
                      class="d-inline-block mr-1"
                      placeholder="搜索退货通知单编号"
                  />

                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <xy-input-button
                        label="仓库"
                        type="input"
                        v-on:change="fromChildren($event,['wareHouseName','wareHouseId'])"
                        :params="['wareHouseName','wareHouseId']"
                        v-model="condition.wareHouseName"
                        modalName="仓库"
                        placeholder="点击选择入库仓库"
                >
                </xy-input-button>
              </b-col>


              <!-- Search 仓库、商品名称、商品69码 -->
              <b-col
                      cols="12"
                      md="4"
              >
                <xy-input-button
                        label="集团公司"
                        type="input"
                        v-on:change="fromChildren($event,['companyName','companyId'])"
                        :params="['companyName','companyId']"
                        v-model="condition.companyName"
                        modalName="销售主体"
                        placeholder="点击选择销售主体"
                >
                </xy-input-button>
              </b-col>


              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="退货原因:"
                    label-for="reason"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="reason"
                      :options="getCodeOptions('sales_returnbound_reasontype')"
                      v-model="condition.reason"
                      name="reason"
                      class="select-size-sm"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                        label="渠道"
                        type="input"
                        v-on:change="fromChildren($event,['channelName','channelId'])"
                        :params="['channelName','channelId']"
                        v-model="condition.channelName"
                        modalName="终端客户和渠道"
                        placeholder="点击选择渠道客户"
                >
                </xy-input-button>
              </b-col>


              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="状态:"
                    label-for="status_id"
                    label-size="sm"
                >
                  <v-select
                      id="status_id"
                      :options="getCodeOptions('sales_returnbound_status')"
                      :clearable="true"
                      v-model="condition.statusId"
                      class="select-size-sm"
                      placeholder="选择状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
                      class="mb-2"
              >
                <xy-input-button
                        label="创建人"
                        type="input"
                        v-on:change="fromChildren($event,['creator','creatorId'])"
                        :params="['creator','creatorId']"
                        v-model="condition.creator"
                        modalName="创建人"
                        placeholder="点击选择创建人"
                >
                </xy-input-button>
              </b-col>


              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                        label="团队店铺"
                        type="input"
                        v-on:change="fromChildren($event,['store_name','store_id'])"
                        :params="['store_name','store_id']"
                        v-model="condition.store_name"
                        modalName="店铺"
                        placeholder="点击选择团队店铺"
                >
                </xy-input-button>
              </b-col>


              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="创建时间:"
                    label-for="add_time"
                    label-size="sm"
                >
                  <flat-pickr
                      :config="{ mode: 'range'}"
                      class="form-control"
                      v-model="condition.addTime"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="product"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.product"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="支持输入空格后多级搜索"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="搜索关联编号"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>


                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="Export"
                >
                  <span class="align-right">导出</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>


      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >
        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>
        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>
        <!-- Columns 显示隐藏详细信息 -->
        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields">
                <template #cell(ext)="data">
                  {{ data.item.ext.code }}
                </template>

                <template #cell(name)="data">
                  [#{{ data.item.product_id }}]{{ data.item.name }}
                </template>

                <template #cell(item_order_no)="data">
                  {{ data.item.order_no }}
                </template>

                <!-- Columns -->
                <template #cell(inventory_type)="data">
                  {{ getCodeLabel('inbound_inventory_type', data.item.inventory_type) }}
                </template>

                <template #cell(product_unit)="data">
                  {{ getCodeLabel('product_unit', data.item.ext.product_unit) }}
                </template>
              </b-table>
            </div>
          </b-card>
        </template>


        <template #cell(no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.id}}]{{ data.item.returnbound_no}}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              variant="success"
              :id="`no-row-${data.item.id}`"
          />
          <b-tooltip
              :target="`no-row-${data.item.id}`"
              placement="top"
          >
            <p class="mb-0">
              退货单编号：{{ data.item.returnbound_no || ""}}
            </p>
            <p class="mb-0">
              销售出库单编号：{{ data.item.outbound_no || ""}}
            </p>
            <p class="mb-0">
              销售单编号：{{ data.item.order_no || ""}}
            </p>

          </b-tooltip>
        </template>

        <template #cell(returnInfo)="data">
          {{ getCodeLabel('sales_returnbound_reasontype', data.item.reason) }}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              variant="success"
              :id="`carrier-row-${data.item.id}`"
              v-show="data.item.carrier"
          />

          <b-tooltip
              :target="`carrier-row-${data.item.id}`"
              placement="top"
          >
            <p class="mb-0">
              退货原因：{{ getCodeLabel('sales_returnbound_reasontype', data.item.reason) }}
            </p>
            <p class="mb-0">
              售后类型：{{ getCodeLabel('sales_returnbound_servicetype', data.item.service) }}
            </p>
            <p class="mb-0">
              费用承担方：{{ getCodeLabel('sales_returnbound_responsibility', data.item.responsibility) }}
            </p>
            <p class="mb-0">
              物流方式：{{ getCodeLabel('delivery_method_return', data.item.delivery_type) }}
            </p>
            <p class="mb-0">
              承运商：{{ data.item.carrier || "" }}
            </p>
            <p class="mb-0">
              快递单号：{{ data.item.express_no || "" }}
            </p>
          </b-tooltip>
        </template>


        <template #cell(company_name)="data">
          {{ data.item.company_name}}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              variant="success"
              :id="`company-row-${data.item.id}`"
          />
          <b-tooltip
              :target="`company-row-${data.item.id}`"
              placement="top"
          >
            <p class="mb-0">
              销售主体：{{ data.item.company_name || ""}}
            </p>
            <p class="mb-0">
              销售员：{{ data.item.sales_name || ""}}
            </p>
            <p class="mb-0">
              联系电话：{{ data.item.sales_mobile || ""}}
            </p>

          </b-tooltip>
        </template>

        <template #cell(our_contact)="data">
          {{ data.item.our_contact}}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              variant="success"
              :id="`our_contact-row-${data.item.id}`"
          />
          <b-tooltip
              :target="`our_contact-row-${data.item.id}`"
              placement="top"
          >
            <p class="mb-0">
              联系电话：{{ data.item.our_mobile || ""}}
            </p>

          </b-tooltip>
        </template>

        <template #cell(other_contact)="data">
          {{ data.item.other_contact}}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              variant="success"
              :id="`other_contact-row-${data.item.id}`"
          />
          <b-tooltip
              :target="`other_contact-row-${data.item.id}`"
              placement="top"
          >
            <p class="mb-0">
              联系电话：{{ data.item.other_mobile || ""}}
            </p>

          </b-tooltip>
        </template>
        <!-- Column: reason -->
        <template #cell(reason)="data">
          {{ getCodeLabel('sales_returnbound_reasontype', data.item.reason) }}
        </template>

        <!-- Column: service -->
        <template #cell(service)="data">
          {{ getCodeLabel('sales_returnbound_servicetype', data.item.service) }}
        </template>

        <!-- Column: delivery_type -->
        <template #cell(delivery_type)="data">
          {{ getCodeLabel('delivery_method_return', data.item.delivery_type) }}
        </template>

        <!-- Column: responsibility -->
        <template #cell(responsibility)="data">
          {{ getCodeLabel('sales_returnbound_responsibility', data.item.responsibility) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(add_time)="data">
          {{ toTime(data.item.add_time) }}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('sales_returnbound_status', data.item.status)}`"
          >
            {{ getCodeLabel('sales_returnbound_status', data.item.status) }}
          </b-badge>
        </template>

        <template #cell(delivery_time)="data">
          发货时间:{{ toTime( data.item.delivery_time) }}
          <br>
          预到时间:{{ toTime( data.item.arrival_time) }}
        </template>

        <template #cell(warehouse_name)="data">
            {{data.item.warehouse_name}}
          <feather-icon
              icon="UserIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="showWarehouseCharge(data.item)"
              variant="success"
          />
        </template>

        <template #cell(business_time)="data" >
          <div v-b-modal.modal-business_time
               @click="changeBusinessTime(data.item)"
               v-if="data.item.is_finance==0||user.role_id==11||user.role_id==12"
          >
            {{ data.item.business_time==null?"暂无(点击修改)":toDate(data.item.business_time) }}
          </div>

          <div v-else
          >
            {{ data.item.business_time==null?"暂无(点击修改)":toDate(data.item.business_time) }}
          </div>
        </template>

        <template #cell(is_finance)="data">
          <b-link
              @click="changeState(data.item)"
              v-if="user.role_id==11||user.role_id==12||user.role_id==14"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.is_finance)}`"
            >
              {{data.item.is_finance==1?"审核通过":"审核未通过"}}
            </b-badge>
          </b-link>

          <b-link
              v-else
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.is_finance)}`"
            >
              {{data.item.is_finance==1?"审核通过":"审核未通过"}}
            </b-badge>
          </b-link>


        </template>

        <template #cell(remark)="data">

          <span v-if="data.value.length > 10"> {{ data.item.remark.toString().substring(0, 10) }}...</span>
          <span v-else>{{ data.item.remark }}</span>
          <feather-icon
              icon="TwitchIcon"
              size="21"
              variant="success"
              :id="`remark-row-${data.item.id}`"
          />
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
          >
            <p class="mb-0">
              详细内容：{{ data.item.remark || ""}}
            </p>

          </b-tooltip>
        </template>

        <template #cell(memo_wh)="data">

          <span v-if="data.value.length > 10"> {{ data.item.memo_wh.toString().substring(0, 10) }}...</span>
          <span v-else>{{ data.item.memo_wh }}</span>
          <feather-icon
              icon="TwitchIcon"
              size="21"
              variant="success"
              :id="`memo_wh-row-${data.item.id}`"
          />
          <b-tooltip
              :target="`memo_wh-row-${data.item.id}`"
              placement="top"
          >
            <p class="mb-0">
              详细内容：{{ data.item.memo_wh || ""}}
            </p>

          </b-tooltip>
        </template>

        <template #cell(attachments_wh)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments_wh'"
                             :id="data.item.attachments_wh"
                             :object_type="'sales_returnbound'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>


            <b-dropdown-item v-if="data.item.status!=3"  @click="isPreReturnBoundCreate(data.item)" >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">我要入库</span>

            </b-dropdown-item>

            <b-dropdown-item  @click="showModal(data.item.id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">入库明细</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showFileAndMemoWindow(data.item.returnbound_id)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">附件上传</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>



      </div>
      <b-modal
          id="modal-inbound-item"
          ok-only
          ok-title="确认"
          cancel-title="取消"
          centered
          size="xl"
          title="查看入库明细"
          ref="modal"
      >
        <stock-returnbound-item-list ref="returnboundItem" :returnbound_id="returnbound_id">

        </stock-returnbound-item-list>
      </b-modal>
    </b-card>

    <!-- 添加附件窗口 -->
    <b-modal
        id="fileModal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="xl"
        title="添加附件"
        ref="fileModal"
        @ok="submitAtt"
        @hidden="clearInput"
    >
      <div>
        <b-col cols="12">
          <label>备注</label>
          <b-form-textarea v-model="contentInfo"></b-form-textarea>
        </b-col>
        <br>
        <b-col cols="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件"
              label-for="attachments_ids"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload :theme="'files'"
                               :attachment_id="'attachments_wh'"
                               :id="memoFile"
                               :object_type="'contract'"
                               :object_id="0"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
      </div>
      <br>
    </b-modal>

    <!--业务时间修改-->
    <b-modal
        id="modal-business_time"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        title="业务时间"
        ref="modal-business_time"
        @ok="submitBusinessTime"
    >
      <b-col md="12">
        <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="业务时间:"
            label-for="label"
            label-size="sm"
            class="mb-1"
        >
          <flat-pickr
              v-model="businessTime"
              class="form-control"
              placeholder="请选择业务时间"
          />
        </b-form-group>
      </b-col>
    </b-modal>

    <!--入库仓库人查看-->
    <b-modal
        id="modal-warehouse-charge"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        title="仓库负责人"
        ref="modal-warehouse-charge"
    >
      <warehouse-charge-list :warehouseId="itemWarehouseId" :warehouseName="itemWarehouseName" >

      </warehouse-charge-list>

    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, toRefs, onMounted, reactive} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  second, compareTodayDate,isNumber
} from '@core/utils/filter'
import salesreturnboundUseList from './warehouseUseList'
import salesreturnboundStore from './salesreturnboundStore'
import { useToast } from 'vue-toastification/composition'
import StockReturnboundItemList from '@/views/apps/stockreturnbounditem/returnboundItem-modal/StockReturnboundItemList'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {useRouter} from "@core/utils/utils";
import CompanySelect from "@/views/apps/company/CompanySelect"
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import Ripple from "vue-ripple-directive";
import {getUserData} from "@/auth/utils";
import flatPickr from 'vue-flatpickr-component'
import WarehouseChargeList from "@/views/apps/warehousecharge/warehousecharge-modal/WarehouseChargeList";
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import axios from "@/libs/axios";
import XyInputButton from '@/views/components/xy/XyInputButton'
export default {
  components: {
    WarehouseChargeList,
    StockReturnboundItemList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    WareHouseSelect,
    CompanySelect,
    flatPickr,
    UserSelect,
    XyInputButton
  },
  data() {
    return {
      returnbound_id:0,
      attachments_ids: '',
      contract: '',
      memoFile:"",
      uploadInfo: [],
      attachmentId: null,
      contentInfo: '',
      fields: [
        { key: 'ext', label: '商品69码'},
        { key: 'name', label: '名称'},
        { key: 'item_order_no', label: '关联编号'},
        {key: 'product_unit', label: '单位'},
        // { key: 'qty', label: '计划出库数量'},
        //{ key: 'trueqty', label: '实际出库数量'},
        // { key: 'receiveqty', label: '对方实收数量'},
        { key: 'salesreturnqty', label: '预计退货数量'},
        { key: 'salesreturnqty_good', label: '实际退货数量(良品)  '},
        { key: 'salesreturnqty_bad', label: '实际退货数量(不良品)  '},
      ],

      advanced_search_modal: false,
      advanced_search_modal_excel: false,
      modalName: '',
      wareHouseName: '',
      companyName:'',
      channelName:'',
      creator:'',
      companyType:'',
      sbItem:{},
      businessTime:'',
      user:{},

      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      checked: [],
      itemWarehouseId:0,
      itemWarehouseName:"",

    }
  },
  mounted() {
    this.isCheckedObj = {};
    this.table.forEach(element => {
      this.$set(this.isCheckedObj, 'index' + element.id, false)
    });
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        if (newVal.length == 0) {
          this.indeterminate = false
          this.allchecked = false
        } else if (newVal.length == this.table.length) {
          this.indeterminate = false
          this.allchecked = true
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const isFinance = 1 - data.is_finance
      store.dispatch('salesreturnbound/state', {id: data.id, isFinance: isFinance}).then(res => {
        this.refetchData()
      })
    },
    showModal(id){
      this.returnbound_id = id
      this.$refs['modal'].show()
    },
    showFileAndMemoWindow: function (attachmentId) {
      this.$refs['fileModal'].show()
      this.attachmentId = attachmentId
    },
    clearInput: function() {
      this.uploadInfo = []
      this.contentInfo = ''
    },
    // 点击全选按钮
    toggleAll(checked) {
      this.checked = []
      this.table.forEach(element => {
        if (checked) {
          this.checked.push(element);
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    },
    // 复选框点击选中与取消
    checkboxClick(id) {
      let index = this.checked.indexOf(id);
      if (index > -1) {
        this.checked.splice(index, 1);
      } else {
        this.checked.push(id);
      }
      this.$forceUpdate()
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('salesreturnbound')) store.registerModule('salesreturnbound', salesreturnboundStore)

    const router = useRouter()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesreturnbound')) store.unregisterModule('salesreturnbound')
    })

    onMounted(() => {
      state.condition = store.getters['salesreturnbound/getCondition']
    })

    const state = reactive({
      condition:{
      },
    })

    const commit = (id) => {
      store.dispatch('salesreturnbound/commit', { returnboundId: id })
          .then((res) => {
            if (res.status === 200) {
              toast.success('提交成功')
              refetchData()
            }
          })
    }

    const onUploaded = function (id, attachment, result) {
      this.uploadInfo = attachment
    }

    const submitAtt = function () {
      let fileArr = []
      for(let i = 0; i < this.uploadInfo.length; i++) {
        fileArr.push(this.uploadInfo[i].attachment_id)
      }
      store.dispatch('salesreturnbound/updateWhInfo', {
        'returnboundId': this.attachmentId,
        'attachmentsWh': JSON.stringify(fileArr).replace("[", "").replace("]", ""),
        'memoWh': this.contentInfo,
      }).then(res => {
        if (res.status === 200) {
          toast.success('提交成功')
          refetchData()
        }
      })
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }


    const changeBusinessTime = function (params) {
      this.businessTime = ''
      this.sbItem = params
    }

    const submitBusinessTime = function () {
      if (compareTodayDate(this.businessTime)){
        this.sbItem.business_time = second(this.businessTime)
        store.dispatch('salesreturnbound/saves', this.sbItem)
            .then(res => {
              if (res.data.code == 0) {
                toast.success('修改成功')
              }else {
                toast.error('修改失败')
              }
              refetchData()
            })
      }else {
        toast.error("请选择小于当日日期")
      }
    }

    const changeIsFinance = function (params) {
      store.dispatch('salesreturnbound/saves', {checked:JSON.stringify(this.checked),isFinance:params})
          .then(res => {
            if (res.data.code == 0) {
              toast.success('修改成功')
            }else {
              toast.error('修改失败')
            }
            this.reload()
          })
    }

    const reload = function () {
      this.checked.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.checked.length = 0
      this.indeterminate = false
      this.allchecked = false
      refetchData()
    }

    const showWarehouseCharge = function (params) {
      this.itemWarehouseId = params.warehouse_id
      this.itemWarehouseName = params.warehouse_name
      this.$refs['modal-warehouse-charge'].show()
    }

    const Export = function () {
      axios({
            url: '/api/exportexcelfile/createSalesReturnBoundExcel',
            method:'get',
            params: {
              warehouseId:state.condition.wareHouseId,
              companyId:state.condition.companyId,
              reason:state.condition.reason,
              statusId:state.condition.statusId,
              returnboundNo:state.condition.salesreturnboundNo,
              channelId:state.condition.channelId,
              creator:state.condition.creatorId,
              addTime:state.condition.addTime,
              name:state.condition.product,
              storeId:state.condition.store_id
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "退货入库单-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const isPreReturnBoundCreate = function (data) {

           if(data.pre_returnbound_id>0){
             store.dispatch('salesreturnbound/isPreReturnBoundCreate', {id:data.id})
                     .then(res => {
                       if (res.data.code == 0) {
                         this.$router.push({name: 'apps-salesreturnbounditem-warehouseList', query: {id: res.data.data.id ,warehouseId:res.data.data.warehouse_id,readonly:1}});
                       }else {
                         toast.error(res.data.data)
                       }
                     })
           }else{
             this.$router.push({name: 'apps-salesreturnbounditem-warehouseList', query: {id: data.id ,warehouseId:data.warehouse_id,readonly:1}});
           }
    }

    const  resetCondition = function () {
      store.commit('salesreturnbound/resetCondition', this.state)
      this.reload()
    }

    const searchByCondition = function () {
      refetchData()
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      wareHouseId,salesreturnboundNo,companyId,reason,business_time,channelId,statusId,creatorId,addTime,product,orderNo,

      // UI
    } = salesreturnboundUseList({
      returnbound_id:router.route.value.query.returnbound_id
    })

    return {
      ...toRefs(state),
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      onUploaded,
      submitAtt,
      wareHouseId,salesreturnboundNo,companyId,reason,business_time,channelId,statusId,creatorId,addTime,product,orderNo,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      commit,
      toTime,
      toDate,
      second,
      // UI
      advanced_search,
      changeBusinessTime,
      submitBusinessTime,
      changeIsFinance,
      reload,
      showWarehouseCharge,
      Export,
      isPreReturnBoundCreate,
      searchByCondition,
      resetCondition,
      fromChildren

    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
